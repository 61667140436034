import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
  accountMenu,
  adjustmentMenu,
  configurationsMenu,
  dashboardMenu,
  demoPagesMenu,
  dynamicMenu,
  reportMenu,
  resultMenu,
  systemSettingMenu,
  ticketMenu,
  userProfileMenu,
} from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

const headers: RouteProps[] = [
  { path: demoPagesMenu.login.path, element: null },
  { path: demoPagesMenu.signUp.path, element: null },
  { path: demoPagesMenu.page404.path, element: null },
  // Dashboard
  {
    path: dashboardMenu.dashboard.path,
    element: <DefaultHeader breadcrumbList={[{ title: dashboardMenu.dashboard.text, to: dashboardMenu.dashboard.path }]} />,
  },
  // Account
  {
    path: accountMenu.pageLayout?.subMenu.userLists.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: accountMenu.pageLayout?.text, to: accountMenu.pageLayout?.path },
          { title: accountMenu.pageLayout?.subMenu.userLists.text, to: accountMenu.pageLayout?.subMenu.userLists.path },
        ]}
      />
    ),
  },
  {
    path: accountMenu.pageLayout?.subMenu.merchantLists.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: accountMenu.pageLayout?.text, to: accountMenu.pageLayout?.path },
          { title: accountMenu.pageLayout?.subMenu.merchantLists.text, to: accountMenu.pageLayout?.subMenu.merchantLists.path },
        ]}
      />
    ),
  },
  // Ticket
  {
    path: ticketMenu.ticket.path,
    element: <DefaultHeader breadcrumbList={[{ title: ticketMenu.ticket.text, to: ticketMenu.ticket.path }]} />,
  },
  // Ticket Details
  {
    path: dynamicMenu.ticket.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: ticketMenu.ticket.text, to: ticketMenu.ticket.path },
          { title: dynamicMenu.ticket.text, to: dynamicMenu.ticket.path },
        ]}
      />
    ),
  },
  // Ticket Details (Strike Report)
  {
    path: dynamicMenu.strikeReportTicket.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: reportMenu.pageLayout?.text, to: reportMenu.pageLayout?.text },
          { title: reportMenu.pageLayout?.subMenu.strikeReport.text, to: reportMenu.pageLayout?.subMenu.strikeReport.path },
          { title: dynamicMenu.strikeReportTicket.text, to: dynamicMenu.strikeReportTicket.path },
        ]}
      />
    ),
  },
  // Draw result
  {
    path: resultMenu.pageLayout?.subMenu.drawResult.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: resultMenu.pageLayout?.text, to: resultMenu.pageLayout?.path },
          { title: resultMenu.pageLayout?.subMenu.drawResult.text, to: resultMenu.pageLayout?.subMenu.drawResult.path },
        ]}
      />
    ),
  },
  // Draw result maintenance
  {
    path: resultMenu.pageLayout?.subMenu.drawResultMaintenance.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: resultMenu.pageLayout?.text, to: resultMenu.pageLayout?.path },
          {
            title: resultMenu.pageLayout?.subMenu.drawResultMaintenance.text,
            to: resultMenu.pageLayout?.subMenu.drawResultMaintenance.path,
          },
        ]}
      />
    ),
  },
  // Adjustment
  {
    path: adjustmentMenu.adjustment.path,
    element: <DefaultHeader breadcrumbList={[{ title: adjustmentMenu.adjustment.text, to: adjustmentMenu.adjustment.path }]} />,
  },
  // Report
  {
    path: reportMenu.pageLayout?.subMenu.strikeReport.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: reportMenu.pageLayout?.text, to: reportMenu.pageLayout?.path },
          { title: reportMenu.pageLayout?.subMenu.strikeReport.text, to: reportMenu.pageLayout?.subMenu.strikeReport.path },
        ]}
      />
    ),
  },
  {
    path: reportMenu.pageLayout?.subMenu.winLossReport.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: reportMenu.pageLayout?.text, to: reportMenu.pageLayout?.path },
          { title: reportMenu.pageLayout?.subMenu.winLossReport.text, to: reportMenu.pageLayout?.subMenu.winLossReport.path },
        ]}
      />
    ),
  },
  {
    path: reportMenu.pageLayout?.subMenu.betNumberReport.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: reportMenu.pageLayout?.text, to: reportMenu.pageLayout?.path },
          { title: reportMenu.pageLayout?.subMenu.betNumberReport.text, to: reportMenu.pageLayout?.subMenu.betNumberReport.path },
        ]}
      />
    ),
  },
  {
    path: reportMenu.pageLayout?.subMenu.jackpotReport.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: reportMenu.pageLayout?.text, to: reportMenu.pageLayout?.path },
          { title: 'Jackpot Strike Report', to: reportMenu.pageLayout?.subMenu.jackpotReport.path },
        ]}
      />
    ),
  },
  // Configuration
  {
    path: configurationsMenu.pageLayout?.subMenu.limitManagement.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: configurationsMenu.pageLayout?.text, to: configurationsMenu.pageLayout?.path },
          {
            title: configurationsMenu.pageLayout?.subMenu.limitManagement.text,
            to: configurationsMenu.pageLayout?.subMenu.limitManagement.path,
          },
        ]}
      />
    ),
  },
  {
    path: configurationsMenu.pageLayout?.subMenu.pricingPackage.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: configurationsMenu.pageLayout?.text, to: configurationsMenu.pageLayout?.path },
          {
            title: configurationsMenu.pageLayout?.subMenu.pricingPackage.text,
            to: configurationsMenu.pageLayout?.subMenu.pricingPackage.path,
          },
        ]}
      />
    ),
  },
  {
    path: configurationsMenu.pageLayout?.subMenu.drawSchedule.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: configurationsMenu.pageLayout?.text, to: configurationsMenu.pageLayout?.path },
          {
            title: configurationsMenu.pageLayout?.subMenu.drawSchedule.text,
            to: configurationsMenu.pageLayout?.subMenu.drawSchedule.path,
          },
        ]}
      />
    ),
  },
  {
    path: configurationsMenu.pageLayout?.subMenu.gameSetting.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: configurationsMenu.pageLayout?.text, to: configurationsMenu.pageLayout?.path },
          {
            title: configurationsMenu.pageLayout?.subMenu.gameSetting.text,
            to: configurationsMenu.pageLayout?.subMenu.gameSetting.path,
          },
        ]}
      />
    ),
  },
  // System settings
  {
    path: systemSettingMenu.pageLayout?.subMenu.announcement.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: systemSettingMenu.pageLayout?.text, to: systemSettingMenu.pageLayout?.path },
          {
            title: systemSettingMenu.pageLayout?.subMenu.announcement.text,
            to: systemSettingMenu.pageLayout?.subMenu.announcement.path,
          },
        ]}
      />
    ),
  },
  {
    path: systemSettingMenu.pageLayout?.subMenu.currency.path,
    element: (
      <DefaultHeader
        breadcrumbList={[
          { title: systemSettingMenu.pageLayout?.text, to: systemSettingMenu.pageLayout?.path },
          {
            title: systemSettingMenu.pageLayout?.subMenu.currency.text,
            to: systemSettingMenu.pageLayout?.subMenu.currency.path,
          },
        ]}
      />
    ),
  },
  // User profile
  {
    path: userProfileMenu.userProfile.path,
    element: <DefaultHeader breadcrumbList={[{ title: userProfileMenu.userProfile.text, to: userProfileMenu.userProfile.path }]} />,
  },
  {
    path: `*`,
    element: <DashboardHeader />,
  },
];

export default headers;
