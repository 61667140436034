import {ApiRequestConfig} from '../type/api-type';
import {serverApi} from './api';

const MAIN_URL = '/general';
const PUBLIC_URL = '/public';

// Get site type drop-down list
export const getSite = (jackpotInclude, modeCode, config: ApiRequestConfig = {}) =>
  serverApi
  .get(`${MAIN_URL}/siteType?jackpotInclude=${jackpotInclude}&modeCode=${modeCode}`, config)
  .then(res => mapToDropDownSchema(res.data.data));

// Get site type drop-down list for Draw Result page - to get game list based on draw date
export const getSiteDR = (jackpotInclude, drawDate, config: ApiRequestConfig = {}) =>
  serverApi
  .get(`${MAIN_URL}/siteType?jackpotInclude=${jackpotInclude}&drawDate=${drawDate}`, config)
  .then(res => mapToDropDownSchema(res.data.data));

// Get all site type not deleted drop-down list
export const getSiteAll = (jackpotInclude, config: ApiRequestConfig = {}) =>
  serverApi
  .get(`${MAIN_URL}/siteTypeAll?jackpotInclude=${jackpotInclude}&isAll=true`, config)
  .then(res => mapToDropDownSchema(res.data.data));

export const getSiteWithIdAsValue = (jackpotInclude, config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/siteType?jackpotInclude=${jackpotInclude}`, config).then(res => mapToDropDownSchema(res.data.data, true));

// Get site type drop-down list
export const getSiteRound = (siteCode, drawDate, config: ApiRequestConfig = {}) =>
  serverApi
  .get(`${MAIN_URL}/siteRound?siteCode=${siteCode}&drawDate=${drawDate}`, config)
  .then(res => mapToDropDownSchema(res.data.data, false, true));

// Get game type drop-down list
export const getGameType = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/gameType`, config).then(res => mapToDropDownSchema(res.data.data));

// Get game type drop-down list(id)
export const getGameType2 = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/gameType`, config).then(res => mapToDropDownSchema(res.data.data, true));

// Get platform drop-down list
export const getPlatform = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/platform`, config).then(res => mapToDropDownSchema(res.data.data, false));

// Get all platform not deleted drop-down list
export const getPlatformAll = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/platform?isAll=true`, config).then(res => mapToDropDownSchema(res.data.data, false));

// Get ticket status drop-down list
export const getStatus = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/status`, config).then(res => mapToDropDownSchema(res.data.data, false));

// Get account pricing package drop-down list
export const getPricingPackage = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/gamePricingPackage`, config).then(res => mapToDropDownSchema(res.data.data, true));

// Get account status drop-down list
export const getAccStatus = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/userStatusList`, config).then(res => mapToDropDownSchema(res.data.data, true));

export const getAccCodeStatus = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/userStatusList`, config).then(res => mapToDropDownSchema(res.data.data, false));

// Get Game Type Category status drop-down list
export const getGameTypeCategory = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/gameTypeCategory`, config).then(res => mapToDropDownSchema(res.data.data, false));

// Get config status drop-down list
export const getConfigStatus = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/configStatus`, config).then(res => mapToDropDownSchema(res.data.data, false));

// Get config status drop-down list
export const getAllMode = (isUseId = true, config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/getAllMode`, config).then(res => mapToDropDownSchema(res.data.data, isUseId));

// Get bet type drop-down list
export const getBetType = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/getAllBetType`, config).then(res => mapToDropDownSchema(res.data.data, true));

// Get user group drop-down list
export const getUserGroup = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/userGroupList`, config).then(res => mapToDropDownSchema(res.data.data, false));

// Get server time
export const getServerTime = async (config: ApiRequestConfig = {}) => {
  const res = await serverApi.get(`${PUBLIC_URL}/server-time?`, config);
  return res.data.data;
};

// Get system parameter api when login
export const getLoginSystemParameter = async (
  pageNum?: string,
  pageSize?: string,
  sortBy?: string,
  sortOrd?: string,
  config: ApiRequestConfig = {}
) => {
  const params = new URLSearchParams();
  params.append('pageNum', pageNum);
  params.append('pageSize', pageSize);
  params.append('sortBy', sortBy);
  params.append('sortOrd', sortOrd);
  const res = await serverApi.get(`${MAIN_URL}/getSystemParameter?${params.toString()}`, config);
  return res.data.data;
};

// Get draw result maintenance status drop-down list
export const getDrawStatus = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/getAllDrawStatus`, config).then(res => mapToDropDownSchema(res.data.data, false));

// Get report type drop-down list
export const getReportType = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/getAllReportType`, config).then(res => mapToDropDownSchema(res.data.data, false));

// Get draw time drop-down list
export const getDrawTime = (siteCode, drawDate, siteMandatory, config: ApiRequestConfig = {}) =>
  serverApi
  .get(`${MAIN_URL}/getAllDrawTime?siteCode=${siteCode}&drawDate=${drawDate}&siteMandatory=${siteMandatory}`, config)
  .then(res => mapToDropDownSchema(res.data.data, false));

// Get provisional history type
export const getProvisionHistoryType = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/getProvisionHistoryType`, config).then(res => mapToDropDownSchema(res.data.data, false));

// Get provisional history status
export const getProvisionStatus = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/provisionStatus`, config).then(res => mapToDropDownSchema(res.data.data, false));

export const getEc2Instances = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/ec2Instances`, config).then(res => res.data.data.content);
/**
 *
 * @param data
 * @param isUseId this param is to determine whether to map 'id' or 'code' to the'value' field as 'code' is the refactored way to do it.
 * @returns
 */

// Get transaction type drop-down list
export const getTransactionType = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/transactionType`, config).then(res => mapToDropDownSchema(res.data.data, false));

export const getMerchantDomain = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/merchantDomains`, config).then(res => res.data.data.content);

export const getMerchantLogoPackage = (config: ApiRequestConfig = {}) =>
  serverApi.get(`${MAIN_URL}/merchantLogoPackages`, config).then(res => res.data.data.content);


function mapToDropDownSchema(data: any, isUseId?: boolean, isRound?: boolean): any {
  return data?.content?.map(d => ({
    value: !isUseId ? d.code : d.id,
    label: isRound ? d.code : d.description,
    gamelist: d.game,
    hasjackpot: d.hasJackpot,
  }));
}
