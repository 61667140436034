export const API_CONSTANTS = {
  ID: 'id' as 'id',
  AREA_GROUP_ID: 'areaGroupId' as 'areaGroupId',
  STATUS_ID: 'statusId' as 'statusId',
};
export const QUERY_KEYS = {
  BASE_SITE: 'baseSite' as 'baseSite',
  BASE_SITEROUND: 'baseSiteRound' as 'baseSiteRound',
  BASE_GAME_TYPE: 'baseGameType' as 'baseGameType',
  BASE_STATUS: 'baseStatus' as 'baseStatus',
  BASE_PLATFORM: 'basePlatform' as 'basePlatform',
  BASE_PRICINGPACKAGE: 'basePricingPackage' as 'basePricingPackage',
  BASE_ACCSTATUS: 'baseAccStatus' as 'baseAccStatus',
  BASE_CONFIGSTATUS: 'baseConfigStatus' as 'baseConfigStatus',
  BASE_MODE: 'baseMode' as 'baseMode',
  BASE_BETTYPE: 'baseBetType' as 'baseBetType',
  SYS_PARAM: 'sysParam' as 'sysParam',
  SERVER_TIME: 'serverTime' as 'serverTime',
  BASE_DRAWSTATUS: 'baseDrawStatus' as 'baseDrawStatus',
  BASE_USERGROUP: 'baseUserGroup' as 'baseUserGroup',
  BASE_REPORTTYPE: 'baseReportType' as 'baseReportType',
  BASE_DRAWTIME: 'baseDrawTime' as 'baseDrawTime',
  BASE_SITE_ID: 'baseSiteId' as 'baseSiteId',
  BASE_PROVHISTYPE: 'baseProvHisType' as 'baseProvHisType',
  BASE_PROVHISSTATUS: 'baseProvHisStatus' as 'baseProvHisStatus',
  BASE_EC2INSTANCE: 'baseEc2Instance' as 'baseEc2Instance',
  BASE_TRANSACTIONTYPE: 'transactionType' as 'transactionType',
  BASE_MERCHANTDOMAIN: 'merchantDomain' as 'merchantDomain',
  BASE_MERCHANTLOGOPACKAGE: 'merchantLogoPackage' as 'merchantLogoPackage',
};
