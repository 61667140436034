import { PasswordInput, TextInput } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'app/common/apiConstants';
import { LOCAL_STORAGE_CONSTANTS } from 'app/common/localStorageConstants';
import Button from 'app/components/bootstrap/Button';
import showNotification from 'app/components/extras/showNotification';
import HeaderLanguage from 'app/components/header/HeaderLanguage';
import AuthContext from 'app/contexts/authContext';
import useLogout from 'app/hooks/useLogout';
import { dashboardMenu, demoPagesMenu } from 'app/menu';
import { postLogin, putUpdate } from 'app/services/authApi';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { getLoginSystemParameter } from 'app/services/baseApi';
import { getUserJackpotPermission } from 'app/services/dashboardApi';

const Login = () => {
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const locale = searchParams.get('locale');

  const [resetSuccess, setResetSuccess] = useState(false);
  const [kickedStatus, setKickedStatus] = useState(false);
  const { t, i18n } = useTranslation();
  const { postLogoutMutation } = useLogout();

  const isFirstTimeLogin = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.FIRST_TIME_LOGIN) === 'true';
  const isKicked = sessionStorage.getItem('IsKicked');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const loginForm = useForm({
    initialValues: {
      username: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
    validate: {
      username: !isFirstTimeLogin && isNotEmpty(t('pleaseFillIn') + t('username') + '.'),
      password: !isFirstTimeLogin && isNotEmpty(t('pleaseFillIn') + t('password') + '.'),
      newPassword: (value, values) => {
        if (isFirstTimeLogin) {
          if (value === '') {
            return t('pleaseFillIn') + t('newPassword') + '.';
          }
          const newPasswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/;
          if (!newPasswordRegex.test(value)) {
            return t('pwdValidate');
          }
          if (value === values.password) {
            return t('newPwdCannotSameOldPwd');
          }
        }
      },
      confirmPassword: (value, values) => {
        if (isFirstTimeLogin) {
          if (value === '') {
            return t('pleaseFillIn') + t('confirmPassword') + '.';
          }
          if (value !== values.newPassword) {
            return t('pwdMustBeSame') + '.';
          }
        }
      },
    },
  });

  const { SYS_PARAM } = QUERY_KEYS;

  const getUserJackpotPermissionQuery = useQuery([], () => getUserJackpotPermission(), {
    enabled: false,
    onSuccess: ({ data }) => {
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.HAVE_JACKPOT, data.content);
    },
    onError: (err: any) => {
      const errorMessage = getErrorMessage(err);
      showNotification(t('failed'), errorMessage, 'danger');
    },
  });

  const getLoginSysParamQuery = useQuery([SYS_PARAM], () => getLoginSystemParameter('1', '5000', 'id', 'ASC'), {
    enabled: false,
    onSuccess: data => {
      const dateRangeFilterParam = data?.content?.filter(d => d.sysPmrValCode === 'DateRangeFilter');
      if (dateRangeFilterParam?.length > 0) {
        Promise.resolve()
          .then(() => {
            localStorage.setItem(LOCAL_STORAGE_CONSTANTS.DATE_RANGE_FILTER, dateRangeFilterParam[0].valInStr);
          })
          .then(() => {
            const permissionLogin = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.PERMISSIONS)?.split(',').includes('USR_PORTALLOGIN');
            if (!permissionLogin) {
              localStorage.setItem(LOCAL_STORAGE_CONSTANTS.FIRST_TIME_LOGIN, 'true');
              navigate(`/${demoPagesMenu.login.path}`);
            } else {
              navigate(`${dashboardMenu.dashboard.path}`);
              location.reload();
            }
          });
      } else {
        showNotification(t('failed'), t('dateRangeFilterParamNotFound', 'danger'));
      }
    },
    onError: (err: any) => {
      showNotification(t('loginFailed'), err.message, 'danger');
    },
  });

  const postLoginMutation = useMutation({
    mutationFn: (loginData: {
      body: {
        username: string;
        password: string;
        newPassword: string;
      };
    }) => postLogin(loginData.body),
    onSuccess: ({ data }) => {
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.ACCESS_TOKEN, data.content.accessToken);
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.PERMISSIONS, data.content.permissions);
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.ROLES, data.content.roles);
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.AUTH_USERNAME, data.content.name);
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.LOGO_PACKAGE, data.content.adminPortalLogoPackage);
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.BRAND_NAME, data.content.brandName);
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.BUCKET_NAME, data.content.bucketName);
      getUserJackpotPermissionQuery.refetch();
      getLoginSysParamQuery.refetch();
      setUser(data.content.name);
      setIsLoggingIn(true);
      setLoginError(false);
    },
    onError: (err: any) => {
      const errorMessage = getErrorMessage(err);
      showNotification(t('failed'), errorMessage, 'danger');
      setIsLoggingIn(true);
      setLoginError(true);
    },
  });

  const getErrorMessage = err => {
    const { response } = err;
    const { data } = response;

    switch (data.message) {
      case 'Bad credentials':
        return t('invalidUP');
      case 'User Inactive!':
        return t('userInactive');
      case 'User Not Found!':
        return t('userNotFound');
      case 'User no access permission.':
        return t('userNoPerm');
      default:
        return '';
    }
  };

  const broadcastLogout = () => {
    const logoutChannel = new BroadcastChannel('logout_channel');
    logoutChannel.postMessage('relogin');
  };

  const onLoginSubmit = async () => {
    if (isFirstTimeLogin) {
      setIsLoggingIn(false);
    } else {
      setIsLoggingIn(true);
    }
    setLoginError(false);
    if (localStorage.getItem(LOCAL_STORAGE_CONSTANTS.AUTH_USERNAME) !== '') {
      Promise.resolve()
        .then(() => {
          broadcastLogout();
        })
        .then(() => {
          !isFirstTimeLogin
            ? postLoginMutation.mutate({
                body: {
                  username: loginForm.values.username,
                  password: loginForm.values.password,
                  newPassword: loginForm.values.newPassword,
                },
              })
            : postLoginMutationInit.mutate({
                body: {
                  username: loginForm.values.username,
                  password: loginForm.values.password,
                  newPassword: loginForm.values.newPassword,
                },
              });
        });
    } else {
      !isFirstTimeLogin
        ? postLoginMutation.mutate({
            body: { username: loginForm.values.username, password: loginForm.values.password, newPassword: loginForm.values.newPassword },
          })
        : postLoginMutationInit.mutate({
            body: { username: loginForm.values.username, password: loginForm.values.password, newPassword: loginForm.values.newPassword },
          });
    }
  };

  const postLoginMutationInit = useMutation({
    mutationFn: (loginDataInit: {
      body: {
        username: string;
        password: string;
        newPassword: string;
      };
    }) => putUpdate(loginDataInit.body),
    onSuccess: ({ data }) => {
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.FIRST_TIME_LOGIN, 'false');
      loginForm.reset();
      // location.reload();
      localStorage.clear();
      navigate(`/${demoPagesMenu.login.path}`);

      setResetSuccess(true);
      setLoginError(false);
    },
    onError: err => {
      showNotification(t('failed'), t('failedChangePwd'), 'danger', true);
      setLoginError(true);
    },
  });

  if (locale == 'th' || locale == 'en') {
    Cookies.set('language', locale);
  }

  useEffect(() => {
    const languageChanged = lng => {
      // Force a re-render when the language changes
      Cookies.set('language', lng);
      loginForm.clearErrors();
    };

    // Listen for language changes
    i18n.on('languageChanged', languageChanged);

    // Clean up the event listener when the component unmounts
    return () => {
      i18n.off('languageChanged', languageChanged);
    };
  }, [i18n]);

  window.onpopstate = () => {
    if (isFirstTimeLogin && !postLogoutMutation.isLoading) {
      loginForm.reset();
      postLogoutMutation.mutate();
    }
  };

  useEffect(() => {
    if (isKicked === 'true') {
      setKickedStatus(true);
      sessionStorage.removeItem('IsKicked');
    } else {
      setKickedStatus(false);
    }
  }, []);

  return (
    <PageWrapper isProtected={false}>
      <Page className="p-0">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
            <Card className="shadow-3d-dark" style={{ marginTop: '200px' }} data-tour="login-page">
              <CardBody>
                <div className="text-center my-5 fw-bold">
                  <p style={{ fontSize: '26px' }}>AsiaLotto - TH</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <HeaderLanguage />
                </div>

                {!isFirstTimeLogin ? (
                  <>
                    {resetSuccess && (
                      <div
                        style={{
                          backgroundColor: '#DFF2BF',
                          color: '#4F8A10',
                          padding: '8px',
                          borderRadius: '5px',
                          marginBottom: '18px',
                        }}
                      >
                        {t('pwdResetReLogin')}
                      </div>
                    )}
                    {kickedStatus && (
                      <div
                        style={{
                          backgroundColor: '#FFCAC8',
                          color: '#850000',
                          padding: '8px',
                          borderRadius: '5px',
                          marginBottom: '18px',
                        }}
                      >
                        {t('securityLogout')}
                      </div>
                    )}
                    <form onSubmit={loginForm.onSubmit(onLoginSubmit)}>
                      <TextInput withAsterisk label={t('username')} {...loginForm.getInputProps('username')} />
                      <PasswordInput
                        autoComplete="true"
                        className="mt-4"
                        withAsterisk
                        label={t('password')}
                        {...loginForm.getInputProps('password')}
                      />
                      <Button type="submit" color="primary" size="lg" className="w-100 mt-4" isDisable={isLoggingIn && !loginError}>
                        {t('login')}
                      </Button>
                    </form>
                  </>
                ) : (
                  <form onSubmit={loginForm.onSubmit(onLoginSubmit)}>
                    <p className="text-center">
                      <strong>{t('firstTimeLoginResetPwd')}</strong>
                    </p>
                    <PasswordInput
                      autoComplete="true"
                      className="mt-4"
                      withAsterisk
                      label={t('newPassword')}
                      {...loginForm.getInputProps('newPassword')}
                    />
                    <PasswordInput
                      autoComplete="true"
                      className="mt-4"
                      withAsterisk
                      label={t('confirmNewPassword')}
                      {...loginForm.getInputProps('confirmPassword')}
                    />
                    <Button type="submit" color="primary" size="lg" className="w-100 mt-4" isDisable={!(isLoggingIn || loginError)}>
                      {t('save')}
                    </Button>
                  </form>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default Login;
