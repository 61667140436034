import Login from 'app/pages/presentation/auth/Login';
import React, { lazy } from 'react';
import {
  accountMenu,
  adjustmentMenu,
  areaGroupMenu,
  configurationsMenu,
  dashboardMenu,
  demoPagesMenu,
  pageLayoutTypesPagesMenu,
  reportMenu,
  resultMenu,
  systemSettingMenu,
  transactionHistoryMenu,
  ticketMenu,
  userProfileMenu,
} from '../menu';

const AUTH = {
  PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
  PAGE_403: lazy(() => import('../pages/presentation/auth/Page403')),
};
const PAGE_LAYOUTS = {
  HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
  HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
  SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
  CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
  BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
  ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
  MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};
const GENERAL = {
  DASHBOARD: lazy(() => import('../pages/presentation/general/DashboardPage')),
  TICKET: lazy(() => import('../pages/presentation/general/TicketPage')),
  ADJUSTMENT: lazy(() => import('../pages/presentation/general/AdjustmentPage')),
  USER_PROFILE: lazy(() => import('../pages/presentation/general/UserProfilePage')),
  AREA_GROUP: lazy(() => import('../pages/presentation/AreaGroupPage')),
};
const ACCOUNT = {
  ACCOUNT: lazy(() => import('../pages/presentation/account/AccountPage')),
  USER_LISTS: lazy(() => import('../pages/presentation/account/UserListsPage')),
  MERCHANT_LISTS: lazy(() => import('../pages/presentation/account/MerchantListsPage')),
};
const RESULT = {
  RESULT: lazy(() => import('../pages/presentation/result/ResultPage')),
  DRAW_RESULT: lazy(() => import('../pages/presentation/result/DrawResultPage')),
  DRAW_RESULT_MAINTENANCE: lazy(() => import('../pages/presentation/result/DrawResultMaintenancePage')),
  INTERFACE_DRAW_RESULT: lazy(() => import('../pages/presentation/result/InterfaceDrawResultPage')),
};
const REPORT = {
  REPORT: lazy(() => import('../pages/presentation/report/ReportPage')),
  STRIKE_REPORT: lazy(() => import('../pages/presentation/report/StrikeReportPage')),
  WIN_LOSS_REPORT: lazy(() => import('../pages/presentation/report/WinLossReportPage')),
  BET_NUMBER_REPORT: lazy(() => import('../pages/presentation/report/BetNumberReportPage')),
  JACKPOT_REPORT: lazy(() => import('../pages/presentation/report/JackpotReportPage')),
  EXPORT_REPORT: lazy(() => import('../pages/presentation/report/ExportReportPage')),
  DRAW_PAYOUT_SUMMARY_REPORT: lazy(() => import('../pages/presentation/report/DrawPayoutSummaryReportPage')),
};
const CONFIGURATIONS = {
  CONFIGURATIONS: lazy(() => import('../pages/presentation/configurations/ConfigurationsPage')),
  LIMIT_MANAGEMENT: lazy(() => import('../pages/presentation/configurations/LimitManagementPage')),
  PRICING_PACKAGE: lazy(() => import('../pages/presentation/configurations/PricingPackage/PricingPackagePage')),
  DRAW_SCHEDULE: lazy(() => import('../pages/presentation/configurations/DrawSchedulePage')),
  GAME_SETTING: lazy(() => import('../pages/presentation/configurations/GameSettingPage')),
  JACKPOT_MAINTENANCE: lazy(() => import('../pages/presentation/configurations/JackpotMaintenancePage')),
  SITE_SETTING: lazy(() => import('../pages/presentation/configurations/SiteSettingPage')),
  CONTENT_MANAGEMENT: lazy(() => import('../pages/presentation/configurations/ContentManagement/ContentManagementPage')),
};
const SYSTEM_SETTING = {
  SYSTEM_SETTING: lazy(() => import('../pages/presentation/system-setting/SystemSettingPage')),
  ANNOUNCEMENT: lazy(() => import('../pages/presentation/system-setting/AnnouncementPage')),
  CURRENCY: lazy(() => import('../pages/presentation/system-setting/CurrencyPage')),
  SYSTEM_PARAMETER: lazy(() => import('../pages/presentation/system-setting/SystemParamPage')),
  PERMISSION_SETTING: lazy(() => import('../pages/presentation/system-setting/PermissionSettingPage')),
  DRAW_SETTLEMENT_CONFIG: lazy(() => import('../pages/presentation/system-setting/DrawSettlementConfigPage')),
  PROVISION_HISTORY: lazy(() => import('../pages/presentation/system-setting/ProvisionHistoryPage')),
};

const TRANSACTION_HISTORY = {
  TRANSACTION_HISTORY: lazy(() => import('../pages/presentation/transaction-history/TransactionHistoryPage')),
};

const presentation = [
  /**
   * General
   */
  {
    path: dashboardMenu.dashboard.path,
    element: <GENERAL.DASHBOARD />,
  },
  {
    path: ticketMenu.ticket.path,
    element: <GENERAL.TICKET />,
  },
  {
    path: adjustmentMenu.adjustment.path,
    element: <GENERAL.ADJUSTMENT />,
  },
  {
    path: userProfileMenu.userProfile.path,
    element: <GENERAL.USER_PROFILE />,
  },
  // {
  //   path: areaGroupMenu.areaGroup.path,
  //   element: <GENERAL.AREA_GROUP />,
  // },
  {
    path: demoPagesMenu.page404.path,
    element: <AUTH.PAGE_404 />,
  },
  {
    path: demoPagesMenu.page403.path,
    element: <AUTH.PAGE_403 />,
  },
  {
    path: demoPagesMenu.login.path,
    element: <Login />,
  },

  /** ************************************************** */

  /**
   * Account pages
   */
  // {
  //   path: accountMenu.pageLayout?.path,
  //   element: <ACCOUNT.ACCOUNT />,
  // },
  {
    path: accountMenu.pageLayout?.subMenu.userLists.path,
    element: <ACCOUNT.USER_LISTS />,
  },
  {
    path: accountMenu.pageLayout?.subMenu.merchantLists.path,
    element: <ACCOUNT.MERCHANT_LISTS />,
  },

  /** ************************************************** */

  /**
   * Result pages
   */
  // {
  //   path: resultMenu.pageLayout?.path,
  //   element: <RESULT.RESULT />,
  // },
  {
    path: resultMenu.pageLayout?.subMenu.drawResult.path,
    element: <RESULT.DRAW_RESULT />,
  },
  {
    path: resultMenu.pageLayout?.subMenu.drawResultMaintenance.path,
    element: <RESULT.DRAW_RESULT_MAINTENANCE />,
  },
  {
    path: resultMenu.pageLayout?.subMenu.interfaceDrawResult.path,
    element: <RESULT.INTERFACE_DRAW_RESULT />,
  },

  /** ************************************************** */

  /**
   * Report pages
   */
  // {
  //   path: reportMenu.pageLayout?.path,
  //   element: <REPORT.REPORT />,
  // },
  {
    path: reportMenu.pageLayout?.subMenu.strikeReport.path,
    element: <REPORT.STRIKE_REPORT />,
  },
  {
    path: reportMenu.pageLayout?.subMenu.winLossReport.path,
    element: <REPORT.WIN_LOSS_REPORT />,
  },
  {
    path: reportMenu.pageLayout?.subMenu.betNumberReport.path,
    element: <REPORT.BET_NUMBER_REPORT />,
  },
  {
    path: reportMenu.pageLayout?.subMenu.jackpotReport.path,
    element: <REPORT.JACKPOT_REPORT />,
  },
  {
    path: reportMenu.pageLayout?.subMenu.drawPayoutSummaryReport.path,
    element: <REPORT.DRAW_PAYOUT_SUMMARY_REPORT />,
  },
  {
    path: reportMenu.pageLayout?.subMenu.exportReport.path,
    element: <REPORT.EXPORT_REPORT />,
  },

  /** ************************************************** */

  /**
   * Configurations pages
   */
  // {
  //   path: configurationsMenu.pageLayout?.path,
  //   element: <CONFIGURATIONS.CONFIGURATIONS />,
  // },
  {
    path: configurationsMenu.pageLayout?.subMenu.limitManagement.path,
    element: <CONFIGURATIONS.LIMIT_MANAGEMENT />,
  },
  {
    path: configurationsMenu.pageLayout?.subMenu.pricingPackage.path,
    element: <CONFIGURATIONS.PRICING_PACKAGE />,
  },
  {
    path: configurationsMenu.pageLayout?.subMenu.drawSchedule.path,
    element: <CONFIGURATIONS.DRAW_SCHEDULE />,
  },
  {
    path: configurationsMenu.pageLayout?.subMenu.gameSetting.path,
    element: <CONFIGURATIONS.GAME_SETTING />,
  },
  {
    path: configurationsMenu.pageLayout?.subMenu.jackpotMaintenance.path,
    element: <CONFIGURATIONS.JACKPOT_MAINTENANCE />,
  },
  {
    path: configurationsMenu.pageLayout?.subMenu.siteSetting.path,
    element: <CONFIGURATIONS.SITE_SETTING />,
  },
  {
    path: configurationsMenu.pageLayout?.subMenu.contentManagement.path,
    element: <CONFIGURATIONS.CONTENT_MANAGEMENT />,
  },

  /** ************************************************** */

  /**
   * System setting pages
   */
  // {
  //   path: systemSettingMenu.pageLayout?.path,
  //   element: <SYSTEM_SETTING.SYSTEM_SETTING />,
  // },
  {
    path: systemSettingMenu.pageLayout?.subMenu.announcement.path,
    element: <SYSTEM_SETTING.ANNOUNCEMENT />,
  },
  {
    path: systemSettingMenu.pageLayout?.subMenu.currency.path,
    element: <SYSTEM_SETTING.CURRENCY />,
  },
  {
    path: systemSettingMenu.pageLayout?.subMenu.systemParameter.path,
    element: <SYSTEM_SETTING.SYSTEM_PARAMETER />,
  },
  {
    path: systemSettingMenu.pageLayout?.subMenu.permission.path,
    element: <SYSTEM_SETTING.PERMISSION_SETTING />,
  },
  {
    path: systemSettingMenu.pageLayout?.subMenu.drawSettlementConfig.path,
    element: <SYSTEM_SETTING.DRAW_SETTLEMENT_CONFIG />,
  },
  {
    path: systemSettingMenu.pageLayout?.subMenu.provisionHistory.path,
    element: <SYSTEM_SETTING.PROVISION_HISTORY />,
  },

  /**
   * Transaction page
   */
  {
    path: transactionHistoryMenu.pageLayout?.path,
    element: <TRANSACTION_HISTORY.TRANSACTION_HISTORY />,
  },
  /** ************************************************** */

  /**
   * Page Layout Types
   */
  {
    path: pageLayoutTypesPagesMenu.blank.path,
    element: <PAGE_LAYOUTS.BLANK />,
  },
  {
    path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
    element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
  },
  {
    path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
    element: <PAGE_LAYOUTS.HEADER />,
  },
  {
    path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
    element: <PAGE_LAYOUTS.SUBHEADER />,
  },
  {
    path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
    element: <PAGE_LAYOUTS.CONTENT />,
  },
  {
    path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
    element: <PAGE_LAYOUTS.ASIDE />,
  },
  {
    path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
    element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
  },
];
const contents = [...presentation];

export default contents;
