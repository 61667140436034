import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideHead } from '../../../layout/Aside/Aside';
import Brand from '../../../layout/Brand/Brand';
import Navigation from '../../../layout/Navigation/Navigation';
import { LOCAL_STORAGE_CONSTANTS } from 'app/common/localStorageConstants';
import { userProfileMenu } from '../../../menu';
import Cookies from 'js-cookie';

const DefaultAside = () => {
  const { asideStatus, setAsideStatus } = useContext(ThemeContext);

  const [doc, setDoc] = useState(localStorage.getItem('facit_asideDocStatus') === 'true' || false);

  const { t, i18n } = useTranslation();

  function hasPermission(permissionId) {
    const permissions = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.PERMISSIONS)?.split(',');
    return permissions ? permissions.includes(permissionId.toString()) : false;
  }
  const haveJackpot = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.HAVE_JACKPOT);
  const permissionViewDashBoard = hasPermission('DSHBRD_VIEW');
  const permissionViewAccounts = hasPermission('ACC_VIEW');
  const permissionViewTickets = hasPermission('TCKLST_VIEW');
  const permissionViewDrawResult = hasPermission('DRES_VIEW');
  const permissionViewReport = hasPermission('RPT_VIEW');
  const permissionViewStrikeReport = hasPermission('RPT_STK_VIEW');
  const permissionViewWinLossReport = hasPermission('RPT_WL_VIEW');
  const permissionViewBetNumReport = hasPermission('RPT_BN_VIEW');
  const permissionViewJackpotReport = hasPermission('RPT_JP_VIEW');
  const permissionViewExportReport = hasPermission('RPT_EXP_EXPORT');
  const permissionViewConfig = hasPermission('CONFIG_VIEW');
  const permissionViewLimit = hasPermission('CFG_LM_VIEW');
  const permissionViewPricing = hasPermission('CFG_PP_VIEW');
  const permissionViewSystemSetting = hasPermission('SYSSET_VIEW');
  const permissionViewSystemParam = hasPermission('SYS_PRM_VIEW');
  const permissionViewUserList = hasPermission('USR_VIEW');
  const permissionViewMerchantList = hasPermission('MERLST_VIEW');
  const permissionViewJackpotMaintenance = hasPermission('CFG_JM_VIEW');
  const permissionViewPermissionSetting = hasPermission('SYS_PER_VIEW');
  const permissionViewDrawSettlementConfig = hasPermission('STL_CONFIG_VIEW');
  const permissionViewProvisionHistory = hasPermission('PROV_HIS_VIEW');
  const permissionViewTransactionHistory = hasPermission('TRX_VIEW');
  const permissionViewInterfaceDrawResult = hasPermission('DRES_IDR_VIEW');
  const permissionViewDrawPayoutSummaryReport = hasPermission('RPT_DPS_VIEW');
  const permissionViewSiteSetting = hasPermission('CFG_SITE_VIEW');
  const permissionViewContentManagement = hasPermission('CFG_CM_VIEW');

  useEffect(() => {
    const languageChanged = lng => {
      // Force a re-render when the language changes
      Cookies.set('language', lng);
      setDoc(!doc);
    };

    // Listen for language changes
    i18n.on('languageChanged', languageChanged);

    // Clean up the event listener when the component unmounts
    return () => {
      i18n.off('languageChanged', languageChanged);
    };
  }, [i18n, doc]);

  //get translated menu when language changed
  const getTranslatedText = key => {
    return t(key);
  };

  const translatedDashboardMenu = permissionViewDashBoard && {
    dashboard: {
      pageID: 'DB001',
      id: 'dashboard',
      text: getTranslatedText('dashboard'),
      path: '/panel/dashboard',
      icon: 'Dashboard',
      subMenu: null,
    },
  };

  const translatedAccountMenu = permissionViewAccounts && {
    accountMenu: {
      id: 'account',
      text: getTranslatedText('accounts'),
      path: '/panel/account',
      icon: 'ManageAccounts',
      subMenu: {
        userLists: {
          pageID: 'UL001',
          id: 'user-lists',
          text: getTranslatedText('userLists'),
          path: '/panel/account/user-lists',
          icon: 'ManageAccounts',
          subMenu: null,
          hide: permissionViewUserList ? false : true,
        },
        merchantLists: {
          pageID: 'ML001',
          id: 'merchant-lists',
          text: getTranslatedText('merchantLists'),
          path: '/panel/account/merchant-lists',
          icon: 'ManageAccounts',
          subMenu: null,
          hide: permissionViewMerchantList ? false : true,
        },
      },
    },
  };

  const translatedTicketMenu = {
    ticket: {
      pageID: 'TK001',
      id: 'ticket',
      text: getTranslatedText('tickets'),
      path: '/panel/ticket',
      icon: 'ConfirmationNumber',
      subMenu: null,
      hide: permissionViewTickets ? false : true,
    },
  };

  const translatedResultMenu = permissionViewDrawResult && {
    resultMenu: {
      id: 'result',
      text: getTranslatedText('results'),
      path: '/panel/result',
      icon: 'AutoGraph',
      subMenu: {
        drawResultMaintenance: {
          pageID: 'DR002',
          id: 'draw-result-maintenance',
          text: getTranslatedText('drawResultMaintenance'),
          path: '/panel/result/maintenance',
          icon: 'AutoGraph',
          subMenu: null,
        },
        drawResult: {
          pageID: 'DR001',
          id: 'draw-result',
          text: 'Draw Result',
          path: '/panel/result/draw-result',
          icon: 'AutoGraph',
          subMenu: null,
          hide: true,
        },
        interfaceDrawResult: {
          pageID: 'IDR001',
          id: 'interface-draw-result',
          text: getTranslatedText('interfaceDrawResult'),
          path: '/panel/result/interface-draw-result',
          icon: 'AutoGraph',
          hide: permissionViewInterfaceDrawResult ? false : true,
        },
      },
    },
  };

  const translatedReportMenu = permissionViewReport && {
    reportMenu: {
      id: 'report',
      text: getTranslatedText('reports'),
      path: '/panel/report',
      icon: 'ReceiptLong',
      subMenu: {
        strikeReport: {
          pageID: 'SR001',
          id: 'strike-report',
          text: getTranslatedText('strikeReport'),
          path: '/panel/report/strike-report',
          icon: 'ReceiptLong',
          hide: permissionViewStrikeReport ? false : true,
        },
        winLossReport: {
          pageID: 'WL001',
          id: 'win-loss-report',
          text: getTranslatedText('winLossReport'),
          path: '/panel/report/win-loss-report',
          icon: 'ReceiptLong',
          hide: permissionViewWinLossReport ? false : true,
        },
        betNumberReport: {
          pageID: 'BN001',
          id: 'bet-number-report',
          text: getTranslatedText('betNumberSalesReport'),
          path: '/panel/report/bet-number-report',
          icon: 'ReceiptLong',
          hide: permissionViewBetNumReport ? false : true,
        },
        jackpotReport: {
          pageID: 'JP001',
          id: 'jackpot-report',
          text: getTranslatedText('jackpotStrikeReport'),
          path: '/panel/report/jackpot-report',
          icon: 'ReceiptLong',
          hide: permissionViewJackpotReport && haveJackpot == 'true' ? false : true,
        },
        drawPayoutSummaryReport: {
          pageID: 'DPS001',
          id: 'draw-payout-summary-report',
          text: getTranslatedText('drawPayoutSummaryReport'),
          path: '/panel/report/draw-payout-summary-report',
          icon: 'ReceiptLong',
          hide: permissionViewDrawPayoutSummaryReport ? false : true,
        },
        exportReport: {
          pageID: 'EX001',
          id: 'export-report',
          text: getTranslatedText('export'),
          path: '/panel/report/export-report',
          icon: 'ReceiptLong',
          hide: permissionViewExportReport ? false : true,
        },
      },
    },
  };

  const translatedConfigurationsMenu = permissionViewConfig && {
    configMenu: {
      id: 'config',
      text: getTranslatedText('configurations'),
      path: '/panel/configurations',
      icon: 'Settings',
      subMenu: {
        limitManagement: {
          pageID: 'LM001',
          id: 'limit-management',
          text: getTranslatedText('limitManagement'),
          path: '/panel/configurations/limit-management',
          icon: 'Settings',
          hide: permissionViewLimit ? false : true,
        },
        jackpotMaintenance: {
          pageID: 'JM001',
          id: 'jackpot-maintenance',
          text: getTranslatedText('jackpotMaintenance'),
          path: '/panel/configurations/jackpot-maintenance',
          icon: 'Settings',
          hide: permissionViewJackpotMaintenance ? false : true,
        },
        pricingPackage: {
          pageID: 'PP001',
          id: 'pricing-package',
          text: getTranslatedText('pricingPackage'),
          path: '/panel/configurations/pricing-package',
          icon: 'Settings',
          hide: permissionViewPricing ? false : true,
        },
        drawSchedule: {
          pageID: 'DS001',
          id: 'draw-schedule',
          text: getTranslatedText('drawSchedule'),
          path: '/panel/configurations/draw-schedule',
          icon: 'Settings',
          hide: true,
        },
        gameSetting: {
          pageID: 'GS001',
          id: 'game-setting',
          text: 'Game Setting',
          path: '/panel/configurations/game-setting',
          icon: 'Settings',
          hide: true,
        },
        siteSetting: {
          pageID: 'SS001',
          id: 'site-setting',
          text: getTranslatedText('siteSetting'),
          path: '/panel/configurations/site-setting',
          icon: 'Settings',
          hide: permissionViewSiteSetting ? false : true,
        },
        contentManagement: {
          pageID: 'CM001',
          id: 'content-management',
          text: getTranslatedText('contentManagement'),
          path: '/panel/configurations/content-management',
          icon: 'Settings',
          hide: permissionViewContentManagement ? false : true,
        },
      },
    },
  };

  const translatedSystemSettingMenu = permissionViewSystemSetting && {
    settingMenu: {
      id: 'systemSetting',
      text: getTranslatedText('systemSetting'),
      path: '/panel/system-setting',
      icon: 'Build',
      subMenu: {
        systemParameter: {
          pageID: 'SP001',
          id: 'system-parameter',
          text: getTranslatedText('systemParameter'),
          path: '/panel/system-setting/system-parameter',
          icon: 'Build',
          hide: permissionViewSystemParam ? false : true,
        },
        permission: {
          pageID: 'PM001',
          id: 'permission',
          text: getTranslatedText('permission'),
          path: '/panel/system-setting/permission',
          icon: 'Build',
          hide: permissionViewPermissionSetting ? false : true,
        },
        announcement: {
          pageID: 'AN001',
          id: 'announcement',
          text: 'Announcement',
          path: '/panel/system-setting/announcement',
          icon: 'Build',
          hide: true,
        },
        currency: {
          pageID: 'CU001',
          id: 'currency',
          text: 'Currency',
          path: '/panel/system-setting/currency',
          icon: 'Build',
          hide: true,
        },
        drawSettlementConfig: {
          pageID: 'DSC001',
          id: 'draw-settlement-config',
          text: getTranslatedText('drawSettlementConfig'),
          path: '/panel/system-setting/draw-settlement-config',
          icon: 'Build',
          hide: permissionViewDrawSettlementConfig ? false : true,
        },
        provisionHistory: {
          pageID: 'PH001',
          id: 'provision-history',
          text: getTranslatedText('provisionHistory'),
          path: '/panel/system-setting/provision-history',
          icon: 'Build',
          hide: permissionViewProvisionHistory ? false : true,
        },
      },
    },
  };

  const translatedTransactionHistoryMenu = permissionViewTransactionHistory && {
    settingMenu: {
      id: 'transactionHistory',
      text: getTranslatedText('transactionHistory'),
      path: '/panel/transactionHistory',
      icon: 'CustomTransaction',
      subMenu: null,
    },
  };

  return (
    <Aside>
      <AsideHead>
        <Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
      </AsideHead>
      <AsideBody>
        <Navigation menu={translatedDashboardMenu} id="aside-dashboard-menu" />
        <Navigation menu={translatedAccountMenu} id="aside-account-menu" />
        <Navigation menu={translatedTransactionHistoryMenu} id="aside-transaction-menu" />
        <Navigation menu={translatedTicketMenu} id="aside-ticket-menu" />
        <Navigation menu={translatedResultMenu} id="aside-result-menu" />
        {/* <Navigation menu={adjustmentMenu} id="aside-adjustment-menu" /> */}
        <Navigation menu={translatedReportMenu} id="aside-report-menu" />
        <Navigation menu={translatedConfigurationsMenu} id="aside-configurations-menu" />
        <Navigation menu={translatedSystemSettingMenu} id="aside-system-setting-menu" />
        <Navigation menu={userProfileMenu} id="aside-user-profile-menu" />
        {/* <Navigation menu={areaGroupMenu} id="aside-area-group-menu" /> */}
        {/* <NavigationLine /> */}
        {/* {!doc && (
          <>
            <Navigation menu={demoPagesMenu} id="aside-demo-pages" />
            <NavigationLine />
            <Navigation menu={pageLayoutTypesPagesMenu} id="aside-menu" />
            <NavigationLine />
            <nav>
              <div className="navigation">
                <div className="navigation-item">
                  <span className="navigation-link navigation-link-pill">
                    <span className="navigation-link-info">
                      <span className="navigation-text">
                        <Popovers title="Aside.tsx" desc={<code>src/pages/_layout/_asides/DefaultAside.tsx</code>}>
                          Aside
                        </Popovers>
                        <code className="ps-3">DefaultAside.tsx</code>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </nav>
          </>
        )}
        {asideStatus && doc && <div className="p-4">Documentation</div>} */}
      </AsideBody>
      {/* <AsideFoot>
        <nav aria-label='aside-bottom-menu'>
          <div className='navigation'>
            <div
              role='presentation'
              className='navigation-item cursor-pointer'
              onClick={() => {
                localStorage.setItem('facit_asideDocStatus', String(!doc));
                setDoc(!doc);
              }}
              data-tour='documentation'>
              <span className='navigation-link navigation-link-pill'>
                <span className='navigation-link-info'>
                  <Icon
                    icon={doc ? 'ToggleOn' : 'ToggleOff'}
                    color={doc ? 'success' : undefined}
                    className='navigation-icon'
                  />
                  <span className='navigation-text'>
                    {t('menu:Documentation') as ReactNode}
                  </span>
                </span>
                <span className='navigation-link-extra'>
                  <Icon
                    icon='Circle'
                    className={classNames(
                      'navigation-notification',
                      'text-success',
                      'animate__animated animate__heartBeat animate__infinite animate__slower',
                    )}
                  />
                </span>
              </span>
            </div>
          </div>
        </nav>
        <User />
      </AsideFoot> */}
    </Aside>
  );
};

export default DefaultAside;
