import { ApiRequestConfig } from 'app/type/api-type';
import { serverApi } from './api';

const MAIN_URL = '/auth';

// Get and manage access tokens for making secure calls to the APIs
export const postLogin = (body = {}, config: ApiRequestConfig = {}) =>
  serverApi.post(`${MAIN_URL}/login`, body, config).then(res => res.data);

export const postLogout = (body = {}, config: ApiRequestConfig = {}) =>
  serverApi.post(`${MAIN_URL}/logout`, body, config).then(res => res.data);

export const postRefresh = (body = {}, config: ApiRequestConfig = {}) =>
  serverApi.post(`${MAIN_URL}/refresh`, body, config).then(res => res.data);

export const putUpdate = (body = {}, config: ApiRequestConfig = {}) =>
serverApi.put(`${MAIN_URL}/update`, body, config).then(res => res.data);