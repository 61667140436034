import PropTypes from 'prop-types';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import { dashboardMenu } from 'app/menu';
import { useTranslation } from 'react-i18next';
import useGetLogo from 'app/hooks/useGetLogo';

interface IBrandProps {
  asideStatus: boolean;
  setAsideStatus(...args: unknown[]): unknown;
}
const Brand: FC<IBrandProps> = ({ asideStatus, setAsideStatus }) => {
  const { t } = useTranslation();
  const merchantLogo = useGetLogo('logo');

  return (
    <div className="brand mb-3">
      <div className="brand-logo">
        <h1 className="brand-title mt-4">
          <Link to={dashboardMenu.dashboard.path} aria-label="Logo" className="d-flex gap-3 align-items-center">
            <img src={merchantLogo} alt="logo" height={35} width="auto" />
          </Link>
        </h1>
        <div className="text-light mt-2 ml-2 text-sm">{t('version')} 1.1.0.28</div>
      </div>
      <button type="button" className="btn brand-aside-toggle" aria-label="Toggle Aside" onClick={() => setAsideStatus(!asideStatus)}>
        <Icon icon="FirstPage" className="brand-aside-toggle-close" />
        <Icon icon="LastPage" className="brand-aside-toggle-open" />
      </button>
    </div>
  );
};
Brand.propTypes = {
  asideStatus: PropTypes.bool.isRequired,
  setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
